<template>
    <div>
        <!-- make a div of vertically and horizontally align -->
        <div class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - (10/100 * 100vh));">
            <div class="d-flex mb-2">{{ loadingMsg }}</div>
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../utils/API";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            loadingMsg: "Loading..."
        }
    },
    computed: {
        ...mapGetters(["currentCustomer"]),
    },
    async mounted() {
        this.loadingMsg = "Generating token..."
        const email = this.currentCustomer.user.email
        const response = await API.post('/custom/generate-jovian-selcare-token', { email })
        const { token } = response.data
        
        this.loadingMsg = "Redirecting..."
        // redirect to jovian selcare
        return window.location.href = `https://jovianselcare.com/account/login/multipass/${token}`
    },
}
</script>